import PropTypes from 'prop-types'
import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'emotion-theming'

import * as Herz from '@rushplay/herz'

import theme from '../theme'
import { GraphqlProvider } from '../graphql-provider'
import { SeonProvider } from '../seon-provider'

import Router, { createRoutes } from './router'
import { AppPreloader } from './app-preloader'
import { GlobalStyles } from './global-styles'
import { HerzTranslation } from './herz-translation'
import { RaffleCampaignsProvider } from './raffle-page/use-fetch-raffle-campaigns'

// LoggerProvider to be removed completely after SEON is confirmed working
// technically we could keep it, but we would need to change to different
// logger instance than one which is console logging based
const consoleLoggerInstance = {
  // no idea why eslint-disable no-console and eslint-enable doesn't work
  // eslint-disable-next-line no-console
  fatal: console.error,
  // eslint-disable-next-line no-console
  error: console.error,
  // eslint-disable-next-line no-console
  warn: console.warn,
  // eslint-disable-next-line no-console
  info: console.log,
  // eslint-disable-next-line no-console
  debug: console.log,
  // eslint-disable-next-line no-console
  trace: console.log,
}

export default function Root(props) {
  return (
    <Herz.Logging.LoggerProvider instance={consoleLoggerInstance}>
      <HelmetProvider>
        <Provider store={props.store}>
          <SeonProvider>
            <GraphqlProvider>
              <ThemeProvider theme={theme}>
                <GlobalStyles />
                <AppPreloader>
                  <HerzTranslation>
                    <RaffleCampaignsProvider>
                      <Router
                        history={props.history}
                        routes={createRoutes(props.store)}
                        // workaround for hot reloading to work when having async routes in application:
                        // https://github.com/gaearon/react-hot-loader/issues/288#issuecomment-321991545
                        // this workaround will not be needed after switching to react-router v4.
                        key={module.hot && new Date()}
                      />
                    </RaffleCampaignsProvider>
                  </HerzTranslation>
                </AppPreloader>
              </ThemeProvider>
            </GraphqlProvider>
          </SeonProvider>
        </Provider>
      </HelmetProvider>
    </Herz.Logging.LoggerProvider>
  )
}

Root.propTypes = {
  history: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  i18nLoader: PropTypes.object.isRequired,
}
