import * as React from 'react'
import * as ReactRouter from 'react-router'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

import HtmlContent from '../common/html-content'

export function RaffleBanner(props) {
  const i18n = I18n.useI18n()

  const isRafflePage = props.location.pathname.includes('/raffle')

  const referrer = props.location.query.referrer

  const isSportsbookPage = props.location.pathname.includes('/sports')

  if (referrer || isSportsbookPage || isRafflePage) {
    return null
  }

  return (
    <ReactRouter.Link to={`/ja/raffle`}>
      <Common.Box
        width="100%"
        background="#E51953"
        height="48px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        fontFamily="Inter"
        fontSize="16px"
        fontWeight="600"
      >
        <HtmlContent
          html={{
            __html: i18n.translate('raffle.topbar.description'),
          }}
        />
      </Common.Box>
    </ReactRouter.Link>
  )
}

RaffleBanner.propTypes = {
  location: PropTypes.object.isRequired,
}
